import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../../components/Accordion.js'
import Layout from '../../../../components/layout'

const GaneshH = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Ganapathi Homam in Bangalore: Book Pandit with 15+ Years of Experience</title>
            <meta name="description" content="Need Pandit for Ganapathi Homam in Bangalore? We provide the Best Tumeric Pooja Services for Lord Ganapati at Home and for new house in Bangalore, India for both North and South Indians." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How much time would it take for the Puja to be completed?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Ganapathi Homam would take about 2-3 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[3].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[3].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Pandit for Ganapathi Hommam in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[3].childImageSharp.fluid.src}",
                  "description": "Need Pandit for Ganapathi Homam in Bangalore? We provide the Best Tumeric Pooja Services for Lord Ganapati at Home and for new house in Bangalore, India for both North and South Indians.",
                  "sku": "0000000009",
                  "mpn": "000009",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "86"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ganesh-ganapathi-homam-services-at-home-in-bangalore-india",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Pandit for Ganapathi Homam in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ganesh-ganapathi-homam-services-at-home-in-bangalore-india",
                  "image": "https://www.namastegod.com${data.allFile.nodes[3].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Ganapathi Homam in Bangalore:<br/>Book Pandit with 15+ Years of Experience</h1>
                        <h1 className="mobile">Ganapathi Homam in Bangalore:<br/>Book Pandit with<br/>15+ Years of Experience</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Need <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandit</a> for Ganapathi Homam in Bangalore? We provide the Best Tumeric Pooja Services for Lord Ganapati at Home and for new house in Bangalore, India for both North and South Indians.</p>
                    <Accordion
          id="1"
          question=" more"
          answer="<p>Ganapathi Homam is a traditional puja done in order to seek the divine blessings of Lord Ganapathi. In Bangalore, Ganapathi Homam is performed in a very grand and a colorful manner. Lord Ganapathi is regarded as the god of good and pleasant beginnings. From ancient times it is believed that a person and the family who performs Ganapathi Homam would lead a happy and prosperous life. Namaste God offers Qualified vedic pandits who have experience more than 15 years in performing Ganapathi Homam ritual. Choose panditji based on your language requirement and book Ganapathi Homam with us on namastegod.com.</p>
          <h4>What is the Ganapathi Homam Cost in Bangalore?</h4>
          <p>NamasteGod provides 3 personalized Ganapathi Homam packages, they are: Small Package (1 Pandit + Puja Samagri), Medium Package ( 2 Pandits + Puja Samagri ) and Large Package ( 3 or more Pandits + Puja Samagri). The cost for these Ganapathi Homam packages in Bangalore ranges from Rs 5,500/- to 8,500/-</p>
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>How much time would it take for the Puja to be completed?
<span>Ganapathi Homam would take about 2-3 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_all}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order})  {
    edges {
      node {
        frontmatter {
          order
          title
          experience
          degree
          regions_all
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ashutosh Pandey", "Pandit Bharat Kumar", "Pandit Hem Chandra Joshi", "Pandit Jayarama Sharma", "Pandit Subodh Pandey", "Pandit TManjunath"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default GaneshH